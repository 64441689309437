import React, { lazy } from 'react'

const TrainingManagement = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TrainingManagement').then(module => ({
    default: module.TrainingManagement,
  }))
)

const TrainingCourseLibraryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCourseLibraryListing'
  ).then(module => ({
    default: module.TrainingCourseLibraryListing,
  }))
)

const TrainingCoursesListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCoursesListing'
  ).then(module => ({
    default: module.TrainingCoursesListing,
  }))
)

const TrainingCourseForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCourseForm'
  ).then(module => ({
    default: module.TrainingCourseForm,
  }))
)

const TrainingProvidersListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersListing'
  ).then(module => ({
    default: module.TrainingProvidersListing,
  }))
)

const TrainingProvidersForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersForm'
  ).then(module => ({
    default: module.TrainingProvidersForm,
  }))
)

const TrainingProvidersHistory = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersHistory'
  ).then(module => ({
    default: module.TrainingProvidersHistory,
  }))
)

const CoursesCategoryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CoursesCategory/CoursesCategoryListing'
  ).then(module => ({
    default: module.CoursesCategoryListing,
  }))
)

const TrainerProfileListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainerProfile/TrainerProfileListing'
  ).then(module => ({
    default: module.TrainerProfileListing,
  }))
)

const TrainerProfileForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TrainerProfile/TrainerProfileForm').then(
    module => ({
      default: module.TrainerProfileForm,
    })
  )
)

const TrainerProfileHistory = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainerProfile/TrainerProfileHistory'
  ).then(module => ({
    default: module.TrainerProfileHistory,
  }))
)

const TrainingExpensesListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingExpenses/TrainingExpensesListing'
  ).then(module => ({
    default: module.TrainingExpensesListing,
  }))
)

const TrainingSurveyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/TrainingSurveyListing'
  ).then(module => ({
    default: module.TrainingSurveyListing,
  }))
)

const SurveyTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/SurveyType/SurveyTypeListing'
  ).then(module => ({
    default: module.SurveyTypeListing,
  }))
)

const SurveyTypeForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/SurveyType/SurveyTypeForm'
  ).then(module => ({
    default: module.SurveyTypeForm,
  }))
)

const SurveyItemListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/SurveyItems/SurveyItemListing'
  ).then(module => ({
    default: module.SurveyItemListing,
  }))
)

const TrainingAPMenu = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingApprovalPolicies/TrainingApprovalPoliciesMenu'
  ).then(module => ({
    default: module.TrainingAPMenu,
  }))
)

const TrainingAPListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingApprovalPolicies/TrainingAPListing'
  ).then(module => ({
    default: module.TrainingAPListing,
  }))
)

const TrainingAPForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingApprovalPolicies/TrainingAPForm'
  ).then(module => ({
    default: module.TrainingAPForm,
  }))
)

const SurveyItemForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/SurveyItems/SurveyItemForm'
  ).then(module => ({
    default: module.SurveyItemForm,
  }))
)

const SurveyFormListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/SurveyForm/SurveyFormListing'
  ).then(module => ({
    default: module.SurveyFormListing,
  }))
)

const SurveyFormForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TrainingSurvey/SurveyForm/SurveyFormForm'
  ).then(module => ({
    default: module.SurveyFormForm,
  }))
)

const BudgetGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/BudgetGroup/BudgetGroupListing').then(
    module => ({
      default: module.BudgetGroupListing,
    })
  )
)
const TargetGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TargetGroup/TargetGroupListing').then(
    module => ({
      default: module.TargetGroupListing,
    })
  )
)

const TrainingManagementListing = lazy(() =>
  import('./TrainingModule/TrainingManagementListing').then(module => ({
    default: module.TrainingManagementListing,
  }))
)

const TrainingRequestListing = lazy(() =>
  import('./TrainingModule/TrainingRequest/TrainingRequestListing').then(
    module => ({
      default: module.TrainingRequestListing,
    })
  )
)
const TrainingMaintenanceListing = lazy(() =>
  import('./TrainingModule/Maintenance/TrainingMaintenanceListing').then(
    module => ({
      default: module.TrainingMaintenanceListing,
    })
  )
)

const TrainingRequestForm = lazy(() =>
  import('./TrainingModule/TrainingRequest/TrainingRequestForm').then(
    module => ({
      default: module.TrainingRequestForm,
    })
  )
)

const TrainingRequestDetail = lazy(() =>
  import('./TrainingModule/TrainingRequest/TrainingRequestDetail').then(
    module => ({
      default: module.TrainingRequestDetail,
    })
  )
)

const MaintenanceBudgetListing = lazy(() =>
  import('./TrainingModule/Maintenance/Budget/MaintenanceBudgetListing').then(
    module => ({
      default: module.MaintenanceBudgetListing,
    })
  )
)

const MaintenanceBudgetForm = lazy(() =>
  import('./TrainingModule/Maintenance/Budget/MaintenanceBudgetForm').then(
    module => ({
      default: module.MaintenanceBudgetForm,
    })
  )
)

const CompulsoryCoursesListing = lazy(() =>
  import(
    './TrainingModule/Maintenance/CompulsoryCourses/CompulsoryCoursesListing'
  ).then(module => ({
    default: module.CompulsoryCoursesListing,
  }))
)

const CompulsoryCoursesForm = lazy(() =>
  import(
    './TrainingModule/Maintenance/CompulsoryCourses/CompulsoryCoursesForm'
  ).then(module => ({
    default: module.CompulsoryCoursesForm,
  }))
)

const TargetHoursListing = lazy(() =>
  import('./TrainingModule/Maintenance/TargetHours/TargetHoursListing').then(
    module => ({
      default: module.TargetHoursListing,
    })
  )
)

const TargetHoursForm = lazy(() =>
  import('./TrainingModule/Maintenance/TargetHours/TargetHoursForm').then(
    module => ({
      default: module.TargetHoursForm,
    })
  )
)

const TrainingRecordsByEmployee = lazy(() =>
  import('./TrainingModule/TrainingRecords/TrainingRecordsByEmployee').then(
    module => ({
      default: module.TrainingRecordsByEmployee,
    })
  )
)
const TrainingEvaluation = lazy(() =>
  import('./TrainingModule/TrainingEvaluation/TrainingEvaluationListing').then(
    module => ({
      default: module.TrainingEvaluationListing,
    })
  )
)

const EmployeeTrainingCourseRecords = lazy(() =>
  import('./TrainingModule/TrainingRecords/EmployeeTrainingCourseRecords').then(
    module => ({
      default: module.EmployeeTrainingCourseRecords,
    })
  )
)

const EmployeeSurveyRecordsListing = lazy(() =>
  import('./TrainingModule/TrainingRecords/EmployeeSurveyRecordsListing').then(
    module => ({
      default: module.EmployeeSurveyRecordsListing,
    })
  )
)

const EvaluationFormRecords = lazy(() =>
  import('./TrainingModule/TrainingRecords/EvaluationFormRecords').then(
    module => ({
      default: module.EvaluationFormRecords,
    })
  )
)

const EventEvaluationListing = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EventEvaluation/EventEvaluationListing'
  ).then(module => ({
    default: module.EventEvaluationListing,
  }))
)

const TrainingEvaluationSurveyFormListing = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EventEvaluation/TrainingEvaluationSurveyFormListing'
  ).then(module => ({
    default: module.TrainingEvaluationSurveyFormListing,
  }))
)

const AttendeesListing = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EventEvaluation/AttendeesListing'
  ).then(module => ({
    default: module.AttendeesListing,
  }))
)

const EvaluationForm = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EventEvaluation/EvaluationForm'
  ).then(module => ({
    default: module.EvaluationForm,
  }))
)

const EventEvaluationResultsListing = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EvaluationResults/EventEvaluationResultsListing'
  ).then(module => ({
    default: module.EventEvaluationResultsListing,
  }))
)

const EventParticipantListing = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EvaluationResults/EventParticipantListing'
  ).then(module => ({
    default: module.EventParticipantListing,
  }))
)

const AttendeeEventSurveyListing = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EvaluationResults/AttendeeEventSurveyListing'
  ).then(module => ({
    default: module.AttendeeEventSurveyListing,
  }))
)

const EvaluationResultView = lazy(() =>
  import(
    './TrainingModule/TrainingEvaluation/EvaluationResults/EvaluationResultView'
  ).then(module => ({
    default: module.EvaluationResultView,
  }))
)

// ------------ My Menu & My Subordinate ----------- //

const ELearning = lazy(() =>
  import('./MyTraining/ELearning/ELearning').then(module => ({
    default: module.ELearning,
  }))
)

const MyScheduledTrainingListing = lazy(() =>
  import('./MyTraining/ScheduledTraining/ScheduledTrainingListing').then(
    module => ({
      default: module.ScheduledTrainingListing,
    })
  )
)

const EventSurveyListing = lazy(() =>
  import('./MyTraining/ScheduledTraining/EventSurveyListing').then(module => ({
    default: module.EventSurveyListing,
  }))
)

const EventSurveyForm = lazy(() =>
  import('./MyTraining/ScheduledTraining/EventSurveyForm').then(module => ({
    default: module.EventSurveyForm,
  }))
)

const MyTrainingMenu = lazy(() =>
  import('./MyTraining/MyTrainingMenu').then(module => ({
    default: module.MyTrainingMenu,
  }))
)

const MyMenuTrainingCheckin = lazy(() =>
  import('./MyTraining/TrainingCheckin/TrainingCheckin').then(module => ({
    default: module.TrainingCheckin,
  }))
)

const MyMenuTrainingCheckinHistory = lazy(() =>
  import('./MyTraining/TrainingCheckin/TrainingCheckinHistory').then(
    module => ({
      default: module.TrainingCheckinHistory,
    })
  )
)

const MyMenuTrainingRequestListing = lazy(() =>
  import('./MyTraining/TrainingRequest/TrainingRequestListing').then(
    module => ({
      default: module.TrainingRequestListing,
    })
  )
)

const MyMenuTrainingRequestForm = lazy(() =>
  import('./MyTraining/TrainingRequest/TrainingRequestForm').then(module => ({
    default: module.TrainingRequestForm,
  }))
)

const MyMenuTrainingRequestDetail = lazy(() =>
  import('./MyTraining/TrainingRequest/TrainingRequestDetail').then(module => ({
    default: module.TrainingRequestDetail,
  }))
)

const MyMenuTrainingCalendar = lazy(() =>
  import('./MyTraining/TrainingCalendar/TrainingCalendar').then(module => ({
    default: module.TrainingCalendar,
  }))
)

const MyMenuRequiredTrainingListing = lazy(() =>
  import('./MyTraining/RequiredTraining/RequiredTrainingListing').then(
    module => ({
      default: module.RequiredTrainingListing,
    })
  )
)

const MyMenuRequiredTrainingResultsListing = lazy(() =>
  import('./MyTraining/RequiredTraining/RequiredTrainingResultsListing').then(
    module => ({
      default: module.RequiredTrainingResultsListing,
    })
  )
)

const MyMenuRequiredTrainingCalendar = lazy(() =>
  import('./MyTraining/RequiredTraining/RequiredTrainingCalendar').then(
    module => ({
      default: module.RequiredTrainingCalendar,
    })
  )
)

const MyMenuRequiredTrainingResultsView = lazy(() =>
  import('./MyTraining/RequiredTraining/RequiredTrainingResultsView').then(
    module => ({
      default: module.RequiredTrainingResultsView,
    })
  )
)

///// Training Subordinate /////

const TrainingManagementMenu = lazy(() =>
  import('./Subordinates/TrainingManagement/TrainingManagementMenu').then(
    module => ({
      default: module.TrainingManagementMenu,
    })
  )
)

const ScheduledTrainingListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/ScheduledTraining/ScheduledTrainingListing'
  ).then(module => ({
    default: module.ScheduledTrainingListing,
  }))
)

const ScheduledTrainingDetails = lazy(() =>
  import(
    './Subordinates/TrainingManagement/ScheduledTraining/ScheduledTrainingDetails'
  ).then(module => ({
    default: module.ScheduledTrainingDetails,
  }))
)

const ScheduledTrainingEventFormListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/ScheduledTraining/ScheduledTrainingEventFormListing'
  ).then(module => ({
    default: module.ScheduledTrainingEventFormListing,
  }))
)

const SubordinateEvaluationResultView = lazy(() =>
  import(
    './Subordinates/TrainingManagement/ScheduledTraining/EvaluationResultView'
  ).then(module => ({
    default: module.EvaluationResultView,
  }))
)

const TrainingFulfillmentMenu = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingFulfillment/TrainingFulfillmentMenu'
  ).then(module => ({
    default: module.TrainingFulfillmentMenu,
  }))
)

const TrainingFulfillmentCompulsoryCoursesListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingFulfillment/TrainingFulfillmentCompulsoryCourses/TrainingFulfillmentCompulsoryCoursesListing'
  ).then(module => ({
    default: module.TrainingFulfillmentCompulsoryCoursesListing,
  }))
)

const TrainingFulfillmentTargetHoursListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingFulfillment/TrainingFulfillmentTargetHours/TrainingFulfillmentTargetHoursListing'
  ).then(module => ({
    default: module.TrainingFulfillmentTargetHoursListing,
  }))
)

const TrainingFulfillmentTargetHoursDetails = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingFulfillment/TrainingFulfillmentTargetHours/TrainingFulfillmentTargetHoursDetails'
  ).then(module => ({
    default: module.TrainingFulfillmentTargetHoursDetails,
  }))
)

const TrainingFulfillmentCompulsoryCoursesDetails = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingFulfillment/TrainingFulfillmentCompulsoryCourses/TrainingFulfillmentCompulsoryCoursesDetails'
  ).then(module => ({
    default: module.TrainingFulfillmentCompulsoryCoursesDetails,
  }))
)

const SubordinateTrainingTree = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateTrainingTree'
  ).then(module => ({
    default: module.SubordinateTrainingTree,
  }))
)

const SubordinateTrainingSubMenu = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateTrainingSubMenu'
  ).then(module => ({
    default: module.SubordinateTrainingSubMenu,
  }))
)

const SubordinateScheduledTraining = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateScheduledTraining/SubordinateScheduledTraining'
  ).then(module => ({
    default: module.SubordinateScheduledTraining,
  }))
)

const SubordinateEventSurveyListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateScheduledTraining/SubordinateEventSurveyListing'
  ).then(module => ({
    default: module.SubordinateEventSurveyListing,
  }))
)

const SubordinateEventSurveyForm = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateScheduledTraining/SubordinateEventSurveyForm'
  ).then(module => ({
    default: module.SubordinateEventSurveyForm,
  }))
)

const SubordinateRequiredTraining = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateRequiredTraining/SubordinateRequiredTraining'
  ).then(module => ({
    default: module.SubordinateRequiredTraining,
  }))
)

const SubordinateRequiredTrainingResultListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateRequiredTraining/SubordinateRequiredTrainingResultListing'
  ).then(module => ({
    default: module.SubordinateRequiredTrainingResultListing,
  }))
)

const SubordinateRequiredTrainingResultDetails = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateRequiredTraining/SubordinateRequiredTrainingResultDetails'
  ).then(module => ({
    default: module.SubordinateRequiredTrainingResultDetails,
  }))
)

const SubordinateRequiredTrainingCalendar = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateRequiredTraining/SubordinateRequiredTrainingCalendar'
  ).then(module => ({
    default: module.SubordinateRequiredTrainingCalendar,
  }))
)

const SubordinateTrainingRequestListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateRequestForTraining/SubordinateTrainingRequestListing'
  ).then(module => ({
    default: module.SubordinateTrainingRequestListing,
  }))
)

const SubordinateTrainingRequestForm = lazy(() =>
  import(
    './Subordinates/TrainingManagement/SubordinateListing/SubordinateRequestForTraining/SubordinateTrainingRequestForm'
  ).then(module => ({
    default: module.SubordinateTrainingRequestForm,
  }))
)

const TrainingRecordsListing = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingRecords/TrainingRecordsListing'
  ).then(module => ({
    default: module.TrainingRecordsListing,
  }))
)

const SubordinateTrainingEvents = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingRecords/SubordinateTrainingEvents'
  ).then(module => ({
    default: module.SubordinateTrainingEvents,
  }))
)

const SubordinateTrainingEvaluationRecords = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingRecords/SubordinateTrainingEvaluationRecords'
  ).then(module => ({
    default: module.SubordinateTrainingEvaluationRecords,
  }))
)

const SubordinateTrainingEvaluationForm = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingRecords/SubordinateTrainingEvaluationForm'
  ).then(module => ({
    default: module.SubordinateTrainingEvaluationForm,
  }))
)

const SubordinateTrainingCalendar = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingCalendar/SubordinateTrainingCalendar'
  ).then(module => ({
    default: module.SubordinateTrainingCalendar,
  }))
)
const SubordinateTrainingCalendarScheduledTraining = lazy(() =>
  import(
    './Subordinates/TrainingManagement/TrainingCalendar/SubordinateScheduledTraining'
  ).then(module => ({
    default: module.SubordinateTrainingCalendarScheduledTraining,
  }))
)

const HomeRoutes = [
  // ------------ Common Setting > Training Management ----------- //
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement',
    },
    component: <TrainingManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCourseLibraryListing',
    },
    component: <TrainingCourseLibraryListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCoursesListing',
    },
    component: <TrainingCoursesListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCourseForm/Add',
    },
    component: <TrainingCourseForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingCourseLibrary/TrainingCourseForm/Edit',
    },
    component: <TrainingCourseForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersListing',
    },
    component: <TrainingProvidersListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersForm/Add',
    },
    component: <TrainingProvidersForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/CoursesCategory/CoursesCategoryListing',
    },
    component: <CoursesCategoryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersForm/Edit',
    },
    component: <TrainingProvidersForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TrainingProviders/TrainingProvidersHistory',
    },
    component: <TrainingProvidersHistory />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingExpenses/TrainingExpensesListing',
    },
    component: <TrainingExpensesListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/TrainingSurveyListing',
    },
    component: <TrainingSurveyListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/SurveyType/SurveyTypeListing',
    },
    component: <SurveyTypeListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/SurveyType/SurveyTypeForm',
    },
    component: <SurveyTypeForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/SurveyItems/SurveyItemListing',
    },
    component: <SurveyItemListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/SurveyItems/SurveyItemForm',
    },
    component: <SurveyItemForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/SurveyForm/SurveyFormListing',
    },
    component: <SurveyFormListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingSurvey/SurveyForm/SurveyFormForm',
    },
    component: <SurveyFormForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/BudgetGroup/BudgetGroupListing',
    },
    component: <BudgetGroupListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TargetGroup/TargetGroupListing',
    },
    component: <TargetGroupListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainerProfile/TrainerProfileListing',
    },
    component: <TrainerProfileListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainerProfile/TrainerProfileForm/Add',
    },
    component: <TrainerProfileForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainerProfile/TrainerProfileForm/Edit',
    },
    component: <TrainerProfileForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainerProfile/TrainerProfileHistory',
    },
    component: <TrainerProfileHistory />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/TrainingApprovalPoliciesMenu',
    },
    component: <TrainingAPMenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Request_Policy',
    },
    component: <TrainingAPListing type="Request_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Nomination_Policy',
    },
    component: <TrainingAPListing type="Nomination_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Self_Registration_Policy',
    },
    component: <TrainingAPListing type="Self_Registration_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Withdrawal_Policy',
    },
    component: <TrainingAPListing type="Withdrawal_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Request_Policy/Add',
    },
    component: <TrainingAPForm mode="add" type="Request_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Nomination_Policy/Add',
    },
    component: <TrainingAPForm mode="add" type="Nomination_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Self_Registration_Policy/Add',
    },
    component: <TrainingAPForm mode="add" type="Self_Registration_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Withdrawal_Policy/Add',
    },
    component: <TrainingAPForm mode="add" type="Withdrawal_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Request_Policy/Edit',
    },
    component: <TrainingAPForm mode="edit" type="Request_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Nomination_Policy/Edit',
    },
    component: <TrainingAPForm mode="edit" type="Nomination_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Self_Registration_Policy/Edit',
    },
    component: <TrainingAPForm mode="edit" type="Self_Registration_Policy" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/Training/TrainingApprovalPolicies/Withdrawal_Policy/Edit',
    },
    component: <TrainingAPForm mode="edit" type="Withdrawal_Policy" />,
  },

  // ------------ Training Management ----------- //
  {
    props: { exact: true, path: '/TrainingManagementListing' },
    component: <TrainingManagementListing />,
  },

  {
    props: { exact: true, path: '/Maintenance/TrainingMaintenanceListing' },
    component: <TrainingMaintenanceListing />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/Budget/MaintenanceBudgetListing',
    },
    component: <MaintenanceBudgetListing />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/Budget/MaintenanceBudgetForm/New',
    },
    component: <MaintenanceBudgetForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/Budget/MaintenanceBudgetForm/Edit',
    },
    component: <MaintenanceBudgetForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/Budget/MaintenanceBudgetForm/View',
    },
    component: <MaintenanceBudgetForm mode="view" />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/CompulsoryCourses/CompulsoryCoursesListing',
    },
    component: <CompulsoryCoursesListing />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/CompulsoryCourses/CompulsoryCoursesForm',
    },
    component: <CompulsoryCoursesForm />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/TargetHours/TargetHoursListing',
    },
    component: <TargetHoursListing />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/TargetHours/TargetHoursForm/New',
    },
    component: <TargetHoursForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/Maintenance/TargetHours/TargetHoursForm/Edit',
    },
    component: <TargetHoursForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/Maintenance/TargetHours/TargetHoursForm/View',
    },
    component: <TargetHoursForm mode="view" />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/TrainingEvaluationListing',
    },
    component: <TrainingEvaluation />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EventEvaluation/EventEvaluationListing',
    },
    component: <EventEvaluationListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/TrainingEvaluation/EventEvaluation/TrainingEvaluationSurveyFormListing',
    },
    component: <TrainingEvaluationSurveyFormListing />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EventEvaluation/AttendeesListing',
    },
    component: <AttendeesListing />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EventEvaluation/EvaluationForm/New',
    },
    component: <EvaluationForm mode="new" />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EventEvaluation/EvaluationForm/Edit',
    },
    component: <EvaluationForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EventEvaluation/EvaluationForm/View',
    },
    component: <EvaluationForm mode="view" />,
  },
  {
    props: { exact: true, path: '/TrainingRequest/TrainingRequestListing' },
    component: <TrainingRequestListing />,
  },

  {
    props: { exact: true, path: '/TrainingRequest/TrainingRequestForm/New' },
    component: <TrainingRequestForm mode="add" />,
  },

  {
    props: { exact: true, path: '/TrainingRequest/TrainingRequestForm/Edit' },
    component: <TrainingRequestForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/TrainingRequest/TrainingRequestDetail' },
    component: <TrainingRequestDetail />,
  },

  {
    props: { exact: true, path: '/TrainingRecords/TrainingRecordsByEmployee' },
    component: <TrainingRecordsByEmployee />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingEvaluation/EvaluationResults/EventEvaluationResultsListing',
    },
    component: <EventEvaluationResultsListing />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingRecords/EmployeeTrainingCourseRecords',
    },
    component: <EmployeeTrainingCourseRecords />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EvaluationResults/EventParticipantListing',
    },
    component: <EventParticipantListing />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EvaluationResults/AttendeeEventSurveyListing',
    },
    component: <AttendeeEventSurveyListing />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingEvaluation/EvaluationResults/EvaluationResultView',
    },
    component: <EvaluationResultView />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingRecords/EmployeeSurveyRecordsListing',
    },
    component: <EmployeeSurveyRecordsListing />,
  },

  {
    props: {
      exact: true,
      path: '/TrainingRecords/EvaluationFormRecords',
    },
    component: <EvaluationFormRecords />,
  },

  // ------------ My Menu & My Subordinate ----------- //
  {
    props: {
      exact: true,
      path: '/MyTraining/E-Learning',
    },
    component: <ELearning />,
  },
  {
    props: {
      exact: true,
      path: '/MyTraining/ScheduledTraining/Listing',
    },
    component: <MyScheduledTrainingListing />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/ScheduledTraining/EventSurveyListing',
    },
    component: <EventSurveyListing />,
  },
  {
    props: {
      exact: true,
      path: '/MyTraining/ScheduledTraining/EventSurveyForm',
    },
    component: <EventSurveyForm />,
  },
  {
    props: {
      exact: true,
      path: '/MyTraining/MyTrainingMenu',
    },
    component: <MyTrainingMenu />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/TrainingCheckin',
    },
    component: <MyMenuTrainingCheckin />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/TrainingCheckinHistory',
    },
    component: <MyMenuTrainingCheckinHistory />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequestForTrainingListing',
    },
    component: <MyMenuTrainingRequestListing />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequestForTrainingForm/Add',
    },
    component: <MyMenuTrainingRequestForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequestForTrainingForm/Edit',
    },
    component: <MyMenuTrainingRequestForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequestForTrainingDetail',
    },
    component: <MyMenuTrainingRequestDetail />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/MyTrainingMenu/TrainingCalendar',
    },
    component: <MyMenuTrainingCalendar />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequiredTrainingListing',
    },
    component: <MyMenuRequiredTrainingListing />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequiredTrainingResultsListing',
    },
    component: <MyMenuRequiredTrainingResultsListing />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequiredTrainingCalendar',
    },
    component: <MyMenuRequiredTrainingCalendar />,
  },

  {
    props: {
      exact: true,
      path: '/MyTraining/RequiredTrainingResultsView',
    },
    component: <MyMenuRequiredTrainingResultsView />,
  },

  // Subordinate
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/trainingsubordinatemenu',
    },
    component: <TrainingManagementMenu />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/ScheduledTrainingListing',
    },
    component: <ScheduledTrainingListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/ScheduledTrainingDetails',
    },
    component: <ScheduledTrainingDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/ScheduledTrainingEventFormListing',
    },
    component: <ScheduledTrainingEventFormListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/EvaluationResultView',
    },
    component: <SubordinateEvaluationResultView />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/TrainingFulfillmentMenu',
    },
    component: <TrainingFulfillmentMenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/TrainingFulfillmentTargetHoursListing',
    },
    component: <TrainingFulfillmentTargetHoursListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/TrainingFulfillmentTargetHoursDetails',
    },
    component: <TrainingFulfillmentTargetHoursDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/TrainingFulfillmentCompulsoryCoursesListing',
    },
    component: <TrainingFulfillmentCompulsoryCoursesListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/TrainingFulfillmentCompulsoryCoursesDetails',
    },
    component: <TrainingFulfillmentCompulsoryCoursesDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatetree',
    },
    component: <SubordinateTrainingTree />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatetrainingsubmenu',
    },
    component: <SubordinateTrainingSubMenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatescheduledtraining',
    },
    component: <SubordinateScheduledTraining />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatescheduledtraining/surveylisting',
    },
    component: <SubordinateEventSurveyListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatescheduledtraining/surveyform',
    },
    component: <SubordinateEventSurveyForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinaterequiredtraining',
    },
    component: <SubordinateRequiredTraining />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinaterequiredtraining/resultlisting',
    },
    component: <SubordinateRequiredTrainingResultListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinaterequiredtraining/resultdetails',
    },
    component: <SubordinateRequiredTrainingResultDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinaterequiredtraining/calendar',
    },
    component: <SubordinateRequiredTrainingCalendar />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatetrainingrequestlisting/listing',
    },
    component: <SubordinateTrainingRequestListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatelisting/subordinatetrainingrequestlisting/form',
    },
    component: <SubordinateTrainingRequestForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/trainingrecordslisting',
    },
    component: <TrainingRecordsListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/trainingmanagement/subordinatetrainingevents',
    },
    component: <SubordinateTrainingEvents />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatetrainingevaluationrecords',
    },
    component: <SubordinateTrainingEvaluationRecords />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/subordinatetrainingevaluationform',
    },
    component: <SubordinateTrainingEvaluationForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/trainingsubordinatemenu/trainingcalendar',
    },
    component: <SubordinateTrainingCalendar />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/trainingmanagement/trainingsubordinatemenu/trainingcalendar/scheduledtraining',
    },
    component: <SubordinateTrainingCalendarScheduledTraining />,
  },
]

export default HomeRoutes
