import React, { lazy } from 'react'
import { TrainingEventQRForm } from './TrainingEventSchedule/TrainingEventQRForm'

const TrainingEventScheduleListing = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventScheduleListing').then(
    module => ({
      default: module.TrainingEventScheduleListing,
    })
  )
)

const TrainingEventScheduleRegistration = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventScheduleRegistration').then(
    module => ({
      default: module.TrainingEventScheduleRegistration,
    })
  )
)

const TrainingEventScheduleForm = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventScheduleForm').then(module => ({
    default: module.TrainingEventScheduleForm,
  }))
)
const TrainingEventScheduleDetail = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventDetails').then(module => ({
    default: module.TrainingEventDetails,
  }))
)

const TrainingEventScheduleQRListing = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventQRListing').then(module => ({
    default: module.TrainingEventQRListing,
  }))
)

const TrainingEventScheduleQRForm = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventQRForm').then(module => ({
    default: module.TrainingEventQRForm,
  }))
)

const TrainingEventScheduleGenerateQR = lazy(() =>
  import('./TrainingEventSchedule/TrainingEventGenerateQR').then(module => ({
    default: module.TrainingEventGenerateQR,
  }))
)

const TrainingSettingListing = lazy(() =>
  import('../TrainingModule/TrainingSetting/SettingMenu').then(module => ({
    default: module.TrainingSettingListing,
  }))
)

const BudgetGroupMatrixListing = lazy(() =>
  import(
    '../TrainingModule/TrainingSetting/BudgetGroupMatrix/BudgetGroupMatrixListing'
  ).then(module => ({
    default: module.BudgetGroupMatrixListing,
  }))
)

const TargetGroupMatrixListing = lazy(() =>
  import(
    '../TrainingModule/TrainingSetting/TargetGroupMatrix/TargetGroupMatrixListing'
  ).then(module => ({
    default: module.TargetGroupMatrixListing,
  }))
)

const ApprovalPolicyMatrixMenu = lazy(() =>
  import(
    '../TrainingModule/TrainingSetting/ApprovalPolicyMatrix/ApprovalPolicyMatrixMenu'
  ).then(module => ({
    default: module.ApprovalPolicyMatrixMenu,
  }))
)

const ApprovalPolicyMatrixListing = lazy(() =>
  import(
    '../TrainingModule/TrainingSetting/ApprovalPolicyMatrix/ApprovalPolicyMatrixListing'
  ).then(module => ({
    default: module.ApprovalPolicyMatrixListing,
  }))
)

const ExpensesListing = lazy(() =>
  import('../TrainingModule/Expenses/ExpensesListing').then(module => ({
    default: module.ExpensesListing,
  }))
)

const ExpensesForm = lazy(() =>
  import('../TrainingModule/Expenses/ExpensesForm').then(module => ({
    default: module.ExpensesForm,
  }))
)
const OngoingTrainingEventLisitng = lazy(() =>
  import(
    '../TrainingModule/TrainingEventAttendance/OngoingTrainingEventLisitng'
  ).then(module => ({
    default: module.OngoingTrainingEventLisitng,
  }))
)

const AttendanceRecordsListing = lazy(() =>
  import(
    '../TrainingModule/TrainingEventAttendance/AttendanceRecordsListing'
  ).then(module => ({
    default: module.AttendanceRecordsListing,
  }))
)

const TrainingFulfillmentMenu = lazy(() =>
  import('../TrainingModule/TrainingFulfillment/TrainingFulfillmentMenu').then(
    module => ({
      default: module.TrainingFulfillmentMenu,
    })
  )
)

const TrainingFulfillmentTargetHoursListing = lazy(() =>
  import(
    '../TrainingModule/TrainingFulfillment/TrainingFulfillmentTargetHours/TrainingFulfillmentTargetHoursListing'
  ).then(module => ({
    default: module.TrainingFulfillmentTargetHoursListing,
  }))
)

const TrainingFulfillmentTargetHoursDetails = lazy(() =>
  import(
    '../TrainingModule/TrainingFulfillment/TrainingFulfillmentTargetHours/TrainingFulfillmentTargetHoursDetails'
  ).then(module => ({
    default: module.TrainingFulfillmentTargetHoursDetails,
  }))
)
const TrainingFulfillmentCompulsoryCoursesListing = lazy(() =>
  import(
    './TrainingFulfillment/TrainingFulfillmentCompulsoryCourses/TrainingFulfillmentCompulsoryCoursesListing'
  ).then(module => ({
    default: module.TrainingFulfillmentCompulsoryCoursesListing,
  }))
)

const TrainingFulfillmentCompulsoryCoursesDetails = lazy(() =>
  import(
    './TrainingFulfillment/TrainingFulfillmentCompulsoryCourses/TrainingFulfillmentCompulsoryCoursesDetails'
  ).then(module => ({
    default: module.TrainingFulfillmentCompulsoryCoursesDetails,
  }))
)
const HRDCClaimListing = lazy(() =>
  import('../TrainingModule/HRDCClaim/HRDCClaimListing').then(module => ({
    default: module.HRDCClaimListing,
  }))
)

const HRDCClaimForm = lazy(() =>
  import('../TrainingModule/HRDCClaim/HRDCClaimForm').then(module => ({
    default: module.HRDCClaimForm,
  }))
)

const trainingRoutes = [
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleListing',
    },
    component: <TrainingEventScheduleListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleRegistration',
    },
    component: <TrainingEventScheduleRegistration />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleForm/Add',
    },
    component: <TrainingEventScheduleForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleDetails',
    },
    component: <TrainingEventScheduleDetail />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleForm/Edit',
    },
    component: <TrainingEventScheduleForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleQRListing',
    },
    component: <TrainingEventScheduleQRListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleQR/Form',
    },
    component: <TrainingEventScheduleQRForm />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/TrainingEventScheduleQR/Generate',
    },
    component: <TrainingEventScheduleGenerateQR />,
  },
  {
    props: { exact: true, path: '/TrainingManagement/Setting/SettingMenu' },
    component: <TrainingSettingListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/Setting/SettingMenu/BudgetGroupMatrixListing',
    },
    component: <BudgetGroupMatrixListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/Setting/SettingMenu/TargetGroupMatrixListing',
    },
    component: <TargetGroupMatrixListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingManagement/Setting/SettingMenu/ApprovalPolicyMatrixMenu',
    },
    component: <ApprovalPolicyMatrixMenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/TrainingManagement/Setting/SettingMenu/ApprovalPolicyMatrixListing/Request_Policy',
    },
    component: <ApprovalPolicyMatrixListing type="Request_Policy" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingManagement/Setting/SettingMenu/ApprovalPolicyMatrixListing/Nomination_Policy',
    },
    component: <ApprovalPolicyMatrixListing type="Nomination_Policy" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingManagement/Setting/SettingMenu/ApprovalPolicyMatrixListing/Self_Registration_Policy',
    },
    component: <ApprovalPolicyMatrixListing type="Self_Registration_Policy" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingManagement/Setting/SettingMenu/ApprovalPolicyMatrixListing/Withdrawal_Policy',
    },
    component: <ApprovalPolicyMatrixListing type="Withdrawal_Policy" />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/Expenses/ExpensesListing',
    },
    component: <ExpensesListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/TrainingEventAttendance',
    },
    component: <OngoingTrainingEventLisitng />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/TrainingEventAttendance/AttendanceRecords',
    },
    component: <AttendanceRecordsListing />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/Expenses/ExpensesForm',
    },
    component: <ExpensesForm />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/TrainingFulfillment/TrainingFulfillmentMenu',
    },
    component: <TrainingFulfillmentMenu />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/HRDCClaim/HRDCClaimListing',
    },
    component: <HRDCClaimListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingModule/TrainingFulfillment/TrainingFulfillmentTargetHoursListing',
    },
    component: <TrainingFulfillmentTargetHoursListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingModule/TrainingFulfillment/TrainingFulfillmentTargetHoursDetails',
    },
    component: <TrainingFulfillmentTargetHoursDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingModule/TrainingFulfillment/TrainingFulfillmentCompulsoryCoursesListing',
    },
    component: <TrainingFulfillmentCompulsoryCoursesListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/TrainingModule/TrainingFulfillment/TrainingFulfillmentCompulsoryCoursesDetails',
    },
    component: <TrainingFulfillmentCompulsoryCoursesDetails />,
  },
  {
    props: {
      exact: true,
      path: '/TrainingModule/HRDCClaim/HRDCClaimForm',
    },

    component: <HRDCClaimForm />,
  },
]

export default trainingRoutes
